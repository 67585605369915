import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import cryptService from "./crypt.service";
import AuthService from "./auth.service";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  DocumentData,
  WhereFilterOp,
  orderBy,
  OrderByDirection,
  onSnapshot,
  addDoc,
  setDoc,
  deleteDoc,
  updateDoc,
  limit,
  startAfter,
} from "firebase/firestore";

import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";

export default class FirebaseService {
  static async uploadStorageFile(
    file: any,
    path: string,
    returnUrl = true,
  ): Promise<any> {
    const fileRef = storageRef(AuthService.storage, path);
    const snapshot = await uploadBytes(fileRef, file);

    if (returnUrl) {
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    }

    return null;
  }

  static async getUserData(): Promise<any> {
    const authService = new AuthService();
    const user = await authService.getCurrentUser();
    if (!user) {
      return;
    }
    const token: any = await user.getIdTokenResult();
    const attributes: any = cryptService.decrypt(token.claims.attributes, true);
    return { ...attributes.user.dbUser, id: attributes.user.dbUser.user_id };
  }

  static async getDefaultDoc(): Promise<any> {
    const authService = new AuthService();
    const user = await authService.getCurrentUser();
    if (!user) {
      throw new Error("FB User not authenticated");
    }
    const token: any = await user.getIdTokenResult();
    const attributes: any = cryptService.decrypt(token.claims.attributes, true);
    return doc(AuthService.db, `tenants/${attributes.user.company_id}`);
  }

  static async getDocData(path: string): Promise<DocumentData | undefined> {
    const defaultDoc = await FirebaseService.getDefaultDoc();
    const ref = doc(defaultDoc, path);
    const docSnap = await getDoc(ref);
    return docSnap.data();
  }

  static async getCollectionDocs(
    path: string,
    extractData: boolean,
    wheres: { fieldPath: string; opStr: WhereFilterOp; value: any }[] = [],
    orderByFields?: [string, OrderByDirection][],
    limitCount?: number,
    startAfterValue?: DocumentData,
  ): Promise<DocumentData[]> {
    const defaultDoc = await FirebaseService.getDefaultDoc();
    let q = query(collection(defaultDoc, path));

    // Apply where filters if provided
    wheres.forEach(({ fieldPath, opStr, value }) => {
      q = query(q, where(fieldPath, opStr, value));
    });

    // Apply orderBy if provided
    if (orderByFields) {
      orderByFields.forEach(([field, direction]) => {
        q = query(q, orderBy(field, direction));
      });
    }

    // Apply limit if provided
    if (typeof limitCount === "number") {
      q = query(q, limit(limitCount));
    }

    // Apply startAfter if provided
    if (startAfterValue) {
      q = query(q, startAfter(startAfterValue));
    }

    const querySnapshot = await getDocs(q);
    if (extractData) {
      return querySnapshot.docs.map((doc) => doc.data());
    }
    return querySnapshot.docs;
  }

  static async docSnapshotListener(
    path: string,
    callback: (data: DocumentData | undefined) => void,
  ): Promise<() => void> {
    const defaultDoc = await this.getDefaultDoc();
    const docRef = doc(defaultDoc.firestore, `${defaultDoc.path}/${path}`);
    const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
      callback(docSnapshot.exists() ? docSnapshot.data() : undefined);
    });
    return unsubscribe;
  }

  static async collectionSnapshotListener(
    path: string,
    extractData: boolean,
    callback: (docs: DocumentData[]) => void,
    wheres: {
      fieldPath: string;
      opStr: WhereFilterOp;
      value: any;
    }[] = [],
    orderByFields?: [string, OrderByDirection][],
    limitCount?: number,
    startAfterValue?: DocumentData,
  ): Promise<() => void> {
    const defaultDoc = await this.getDefaultDoc();
    let q = query(
      collection(defaultDoc.firestore, `${defaultDoc.path}/${path}`),
    );

    // Apply where filters if provided
    wheres.forEach(({ fieldPath, opStr, value }) => {
      q = query(q, where(fieldPath, opStr, value));
    });

    // Apply orderBy if provided
    if (orderByFields) {
      orderByFields.forEach(([field, direction]) => {
        q = query(q, orderBy(field, direction));
      });
    }

    // Apply limit if provided
    if (typeof limitCount === "number") {
      q = query(q, limit(limitCount));
    }

    // Apply startAfter if provided
    if (startAfterValue) {
      q = query(q, startAfter(startAfterValue));
    }

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (extractData) {
        const docs = querySnapshot.docs.map((doc) => doc.data());
        callback(docs);
      } else {
        callback(querySnapshot.docs);
      }
    });
    return unsubscribe;
  }

  static async addDocument(
    path: string,
    data: DocumentData,
    addDocId = true,
  ): Promise<void> {
    const defaultDoc = await FirebaseService.getDefaultDoc();
    const collectionRef = collection(defaultDoc, path);
    const docRef = await addDoc(collectionRef, data);

    if (addDocId) {
      await setDoc(docRef, { ...data, id: docRef.id }, { merge: true });
    }
  }

  static async setDocument(
    path: string,
    data: DocumentData,
    merge = false,
  ): Promise<void> {
    const defaultDoc = await FirebaseService.getDefaultDoc();
    const docRef = doc(defaultDoc, path);
    await setDoc(docRef, data, { merge });
  }

  static async updateDocument(path: string, data: DocumentData): Promise<void> {
    const defaultDoc = await FirebaseService.getDefaultDoc();
    const docRef = doc(defaultDoc, path);
    await updateDoc(docRef, data);
  }

  static async deleteDocument(path: string): Promise<void> {
    const defaultDoc = await FirebaseService.getDefaultDoc();
    const docRef = doc(defaultDoc, path);
    await deleteDoc(docRef);
  }
}
